import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Detail from '../modules/Detail';
import Home from '../modules/Home/index';

function RoutesComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/home" />} />
        <Route exact path="/detail/:id" element={<Detail />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/:promoter" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;