import React from 'react';
import './Style.scss';

const Card = ({ card, style,onClick }) => {
  return (
    <div className='container' style={style} onClick={onClick}>
      <div className='card-body'>
        
        <img className='card__img' src={card.thumbnailURL} alt={card.title} />
        

        <div className='card-bottom'>
          
          <h5 className='card__pais'>{card.destination}</h5>
          <h3 className='card__titulo'>{card.title}</h3>
          
          <div className='contenedor__descripcion'>
            <p className='card__descripcion'>{card.description}</p>
          </div>
          
          <p className='card__precio'>{card.price.prefix} {card.price.currency} {card.price.value}</p>

        </div>


      </div>
    </div>
  );
}

export default Card;