import React from 'react';
import ReactDOM from 'react-dom/client';
import RoutesComponent from './routes/routes';
import { DataTravelProvider } from './context/dataTravelContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <DataTravelProvider>
          <RoutesComponent/>
      </DataTravelProvider> 
   
  </React.StrictMode>
);