import React from 'react';
import './style.scss';

const Loader = () => {

  return (
    <div className='loader'>
      <div className='spinner'></div>
    </div>
  );

};

export default Loader;
