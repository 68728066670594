import React from 'react'
import './style.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDataTravel from '../../../../hooks/useDataTravel';



const TitleTour = () => {

  const {data,promoter,loading,error}=useDataTravel();
  
  if (loading) return <p></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="title__tour">
        <div className='title__tour-datapromotor'>
            <h2>{data.title}</h2>
            <div className='promotor'>
                <div>
                    <img className="propotor__img" src={promoter.pictureURL} alt="Promotor"/>
                </div>
                <div className='promotor__box-data'> 
                    <p className="promotor__name">{promoter.fullname}</p>
                    <a className="tour__link" href={promoter.viewMoreURL} target='_blank' rel="noopener noreferrer">Ver más 
                        <span><FontAwesomeIcon icon={faArrowRight} /></span>
                    </a>
                </div> 
            </div>
            
        </div>
       
    </div>
  )
}

export default TitleTour