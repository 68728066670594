import React from 'react';
import { ToursProvider } from '../../context/dataToursContext';
import HomeContent from './HomeContent'; // Asegúrate de que HomeContent esté en el mismo directorio que index.jsx

const Home = () => {
  return (
    <ToursProvider>
      <HomeContent />
    </ToursProvider>
  );
};

export default Home;
