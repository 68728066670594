const getPromoterFromUrl=()=>{
  const url = window.location.href; // Obtener la URL actual
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;
  const subdominio = hostname.split('.')[0];
  return subdominio
  
};

export const getTours = async () => {
  const promoter = getPromoterFromUrl(); // Obtener el valor del promoter desde la URL

  try {
    const response = require(`../content/tours/${promoter}.json`);
    return response;
  } catch(error) {
    console.error('Fetch error:', error);
    throw new Error('Tours no encontrado');
  }
  /*

  let apiUrl;
  const hostname = window.location.hostname;
  
  if (hostname.includes('localhost')) {
    // Use local API endpoint
    apiUrl = `http://localhost:80/tours?promoter=${promoter}&page=0&size=4`;
  } else if (hostname.endsWith('.local')) {
    // Use local API endpoint
    apiUrl = `http://apigw.trippromoter.local/tours?promoter=${promoter}&page=0&size=4`;
  } else {
    // Use production API endpoint
    apiUrl = `https://api.trippromoter.com/tours?promoter=${promoter}&page=0&size=4`;
  }

  try {
    const response = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status===404){
      throw new Error('Promoter no encontrado');
    }

    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response text:', errorText);
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const jsonData = await response.json();
      return jsonData;  
    } else {
      const errorText = await response.text();
      console.error('Error: Expected JSON but received:', errorText);
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    throw error;
  }
  */
};
  