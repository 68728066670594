import React from 'react';
import "./style.scss";
import fecha from './assets/fecha.svg';
import duracion from './assets/duracion.svg';
import precio from './assets/precio.svg';
import useDataTravel from "../../../../hooks/useDataTravel";

const DataTravelComponent = () => {
  const {data, loading, error}= useDataTravel();

  if (loading) return <p></p>;
  if (error) return <p> </p>;

  const {date1,date2,days,price}=data;

  return (
    <div className="data-component">
      
      <div className="data one">
          
          <div className="icon">
          
            <img src={fecha} alt='icono de calendario'/>
          
          </div>

          <div className="more-info">
            <h3>Fechas</h3>
            <div className="fechas"> 
              <p>{date1} a {date2}</p>
            </div>
          </div>
        
      </div>
      
      <div className="data two">
          
          <div className="icon">

            <img src={duracion} alt='icono de reloj'/>
          
          </div>
        
          <div className="more-info">
            <h3>Duración</h3>
            <p>{days} días</p>
          </div>

      </div>

      <div className="data three">
          
          <div className="icon">
            
            <img src={precio} alt='icono de monedas'/>

          </div>

         
          <div className="more-info">
            <h3>Precio</h3>
            <p>{price.prefix} {price.currency} {price.value}</p>
          </div>

      </div>

    </div>
    
  );
};

export default DataTravelComponent;
