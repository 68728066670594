import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import whatsapp_icon from './assets/whatsapp_green.svg';
import './styles.scss';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'; // Importar el plugin isBetween
import useDataTravel from '../../../../hooks/useDataTravel';

dayjs.extend(isBetween); // Usar el plugin isBetween

const ConsultForm = forwardRef(({ tourId }, ref) => {
  const formRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);
  const {data}=useDataTravel();

  const headerOffset = 6 * 16; // 6rem in pixels (assuming 1rem = 16px)

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    date: '',
    quantity: '',
    text: ''
  });

  const [showForm, setShowForm] = useState(false);
  const [isFormExpanded, setIsFormExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [focusBorder, setFocusBorder] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleForm() {
      setShowForm(true);
      setIsFormExpanded(true);
      titleRef.current.focus();
      if (!isInViewport(formRef.current)) {
        const elementPosition = formRef.current.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    },
    isExpanded() {
      return isFormExpanded;
    }
  }));

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.document.documentElement.clientWidth)
    );
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    const message = encodeURI('Hola! Queria consultar por el viaje *' + data.title + '*');
    window.open('https://wa.me/5491127516586?text=' + message, '_blank').focus();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Field changed: ${name}, Value: ${value}`);
    setFormData({
      ...formData,
      [name]: value || '' // Asegurarse de que siempre haya un valor definido
    });
  };

  useEffect(() => {
    console.log('Form state changed:', showForm);
  }, [showForm]);

  const onDataChange = (dateString) => {
    setFormData({
      ...formData,
      date: dateString
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\+?[0-9]{1,15}$/;
    return re.test(String(phone));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones
    if (!formData.fullname || !formData.email || !formData.phone || !formData.date || !formData.quantity || !formData.text) {
      setMessage('Por favor, complete todos los campos.');
      setMessageColor('red');
      console.log('Validación fallida: faltan campos');
      return;
    }

    if (!validateEmail(formData.email)) {
      setMessage('Por favor, ingrese un correo electrónico válido.');
      setMessageColor('red');
      console.log('Validación fallida: email inválido');
      return;
    }

    if (formData.phone && !validatePhone(formData.phone)) {
      setMessage('Por favor, ingrese un número de teléfono válido.');
      setMessageColor('red');
      console.log('Validación fallida: teléfono inválido');
      return;
    }

    if (/\d/.test(formData.fullname)) {
      setMessage('El nombre no puede contener números.');
      setMessageColor('red');
      console.log('Validación fallida: nombre contiene números');
      return;
    }

    // Validar que la fecha esté dentro del rango permitido
    const startDate = dayjs();
    const endDate = dayjs('2024-12-31');
    const selectedDate = dayjs(formData.date);

    if (!selectedDate.isBetween(startDate, endDate, null, '[]')) {
      setMessage('Por favor, seleccione una fecha dentro del rango permitido (2024).');
      setMessageColor('red');
      console.log('Validación fallida: fecha fuera de rango');
      return;
    }

    let apiUrl;
    const hostname = window.location.hostname;
    
    if (hostname.endsWith('.local')) {
      // Use local API endpoint
      apiUrl = 'http://api.trippromoter.local/tour/message';
    } else {
      // Use production API endpoint
      apiUrl = 'https://api.trippromoter.com/tour/message';
    }

    try {
      const data = {
        tourId: tourId,
        fullname: formData.fullname,
        email: formData.email,
        phone: formData.phone,
        date: formData.date,
        quantity: parseInt(formData.quantity),
        text: formData.text
      };

      console.log('Enviando datos:', data);

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${response.statusText} - ${responseText}`);
      }

      const responseText = await response.text();

      if (response.status === 201) {
        setMessage('Enviado exitosamente');
        setMessageColor('blue');
        setShowForm(false); // Contraer el formulario después de enviar
        console.log('Enviado exitosamente: 201');
        return;
      }

      if (!responseText) {
        setMessage('¡Enviado exitosamente!');
        setMessageColor('blue');
        setShowForm(false); // Contraer el formulario después de enviar
        console.log('Enviado exitosamente: sin texto de respuesta');
        return;
      }

      let result;
      try {
        result = JSON.parse(responseText);
        console.log(result);
      } catch (error) {
        throw new Error('Failed to parse JSON response: ' + responseText);
      }

      setMessage('Enviado exitosamente');
      setMessageColor('blue');
      setShowForm(false); // Contraer el formulario después de enviar
      console.log('Enviado exitosamente: con respuesta JSON');
    } catch (error) {
      console.error('Error:', error);
      setMessage('No se pudo enviar los datos, intente nuevamente');
      setMessageColor('red');
    }
  };

  const toggleForm = () => {
    setShowForm(prevState => !prevState); // Alternar el estado previo
  };

  return (
    <div ref={formRef} className="container">
      <div 
        className={`dropdown ${showForm ? "open" : ""}`}
        tabIndex="-1"
        ref={titleRef}
        style={{ outline: focusBorder ? '2px solid black' : 'none' }}
      >
        <div className='dropdown__title'>
          <h2>Consulta por este viaje</h2>
        </div>
        <div className='dropdown__description'>
          <p>¡Quedan pocos lugares disponibles!</p> 
            <button 
              className={'button'} 
              onClick={handleButtonClick}
              ref={buttonRef}
            >
              <div className={'interior'}>
                <span>Consultar</span>
                <img className='wp_img' src={whatsapp_icon} alt='icono de whatsapp'/>
              </div>
            </button>
        </div>
      </div>
    </div>
  );
});

export default ConsultForm;