import React from 'react';
import './styles.scss';
import useDataTravel from '../../hooks/useDataTravel';
import SocialMedia from '../SocialMedia';

const Footer = React.forwardRef((props, ref) => {
    const { promoter, loading } = useDataTravel();
    if (loading) return <p></p>;

    return (
        <footer className="footer" ref={ref}>
            <div className="footer__column-logo">
                <ul>
                    <li>
                        <img src={promoter.logoURL} alt="logo trip promoter" className="logo" />
                    </li>
                    <li>
                        <p>(c) 2024 Powered By Trip Promoter LLC</p>
                    </li>
                </ul>
            </div>

            <div className='border__footer'></div>

            <div className="footer__column-contact">
                <ul>
                    <li>
                        <span>BTCOM LEG N°17779</span>
                    </li>
                    <li>
                        <a href="https://wa.me/5491127516586" target="_blank" rel="noreferrer" >+5491127516586</a>
                    </li>
                    <li>
                        <a href="mailto:">ventas@buentour.com</a>
                    </li>
                </ul>
            </div>

            <div className='border__footer'></div>

            <div className="footer__column-icons">
                <p>Follow Us</p>
                <SocialMedia />
            </div>
        </footer>
    );
});

export default Footer;