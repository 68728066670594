import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import useDataTravel from '../../hooks/useDataTravel';
import whatsapp_icon from './assets/whatsapp_green.svg';

const Header = ({ onBuyClick, showGalleryLink }) => {
    const { promoter, loading, error } = useDataTravel();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const headerRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const scrollToSection = (event, id) => {
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -100; // Ajusta este valor según la altura de tu header
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    /*
    const handleBuyClick = (event) => {
        event.preventDefault();
        if (formRef.current) {
        formRef.current.toggleForm();
        scrollToSection(event, 'form');
        }
    };*/

    if (loading) return <p></p>;
    if (error) return <p></p>;

    return (
        <header className='header' ref={headerRef}>
            <div className="menu-bar-pc">
                <a href="/home">
                    <img src={promoter.logoURL} alt="logo trip promoter" className="logo" />
                </a>

                <nav className="menu-principal">
                    <a className='li__header-desktop' href="#datatravel" onClick={(e) => scrollToSection(e, 'datatravel')}>Información general</a>
                    <a className='li__header-desktop' href="#includes" onClick={(e) => scrollToSection(e, 'includes')}>Overview</a>
                    <a className='li__header-desktop' href="#tourplan" onClick={(e) => scrollToSection(e, 'tourplan')}>Tour Plan</a>
                    {showGalleryLink && <a className='li__header-desktop' href="#gallery" onClick={(e) => scrollToSection(e, 'gallery')}>Galería de Fotos</a>}
                    <button className='nav__btn-comprar' onClick={onBuyClick}>
                        <div className='interior'>
                            <span>Consultar</span>
                            <img className='wp_img' src={whatsapp_icon} alt='icono de whatsapp'/>
                        </div>
                    </button>
                </nav>
            </div>

            <div className={`menu-bar-movil ${isMenuOpen ? 'active' : ''}`}>
                <a href="/home">
                    <img src={promoter.logoURL} alt="logo trip promoter" className="logo" />
                </a>

                <div className="burger-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon 
                        icon={isMenuOpen ? faTimes : faBars} 
                        className={isMenuOpen ? 'icon-close' : 'icon-open'}
                    />
                </div>

                <div className={`slideMenu ${isMenuOpen ? 'active' : ''}`}>
                    <nav className="menu-principal">
                        <a href="#datatravel" onClick={(e) => { scrollToSection(e, 'datatravel'); closeMenu(); }}>Información general</a>
                        <a href="#includes" onClick={(e) => { scrollToSection(e, 'includes'); closeMenu(); }}>Overview</a>
                        <a href="#tourplan" onClick={(e) => { scrollToSection(e, 'tourplan'); closeMenu(); }}>Tour Plan</a>
                        {showGalleryLink && <a href="#gallery" onClick={(e) => { scrollToSection(e, 'gallery'); closeMenu(); }}>Galería de Fotos</a>}
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;