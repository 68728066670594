import React, { createContext, useState, useEffect } from 'react';
import { getTours } from '../services/getTours';

export const ToursContext = createContext();

export const ToursProvider = ({ children }) => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTours = async () => {
      setError(null);
      try {
        const toursData = await getTours();
        setTours(toursData.tours || []);
      } catch (error) {
        setError(error.message || 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  return (
    <ToursContext.Provider value={{ tours, loading, error }}>
      {children}
    </ToursContext.Provider>
  );
};
