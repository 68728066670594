import React, { useState } from 'react';
import Card from './Components/Card';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useTours  from '../../hooks/useTours';
import {useNavigate} from 'react-router-dom';
import Loader from '../../components/Loader/Loader';


const HomeContent = () => {
  const {tours,error,loading}=useTours();
  const totalCards = tours?tours.length:0;

  const [cardsPerPage, setCardsPerPage] = useState(4); // Por defecto, tarjetas por página
  const [currentPage, setCurrentPage] = useState(1);

  const navigate= useNavigate();

  const endIndex = currentPage * cardsPerPage;
  const startIndex = endIndex - cardsPerPage; 

  const nextCardsPerPage = () => {
    setCardsPerPage(prevCardsPerPage => prevCardsPerPage + 1);
    setCurrentPage(1); // Opcional: Restablecer la página actual a la primera página
  };


  const prevCardsPerPage = () => {
    if (cardsPerPage > 1) {
      setCardsPerPage(prevCardsPerPage => prevCardsPerPage - 1);
      setCurrentPage(1); // Opcional: Restablecer la página actual a la primera página
    }
  };

  const handleCardClick = (id) => {
    navigate(`/detail/${id}`);
  };


  if (loading) { // Manejo de loading
    return <Loader />;
  }

  if(error){
    return <div>Error: {error}</div>;
  }


  return (
    <div className="home__container">
      <div className='home__header'>
        <h1 className='home__titulo'>Mis viajes</h1>
        <a className="home__link" href='index'>Ver todos los viajes</a>
      </div>
      <div className='app__cards'>
        <button className={`slider-button left ${currentPage === 1 ? 'disabled' : ''}`} onClick={prevCardsPerPage}>

            <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {tours.slice(startIndex, endIndex).map(tour => (
          <Card key={tour.id} card={tour} onClick={() => handleCardClick(tour.id)} />
        ))}
        <button className={`slider-button right ${endIndex >= totalCards ? 'disabled' : ''}`} onClick={nextCardsPerPage}>
            <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}

export default HomeContent;