import React from 'react';
import './style.scss'
import useDataTravel from '../../../../hooks/useDataTravel';

const Location = () => {
    
  const {data,loading,error}=useDataTravel();

  if (loading) return <p></p>;
  if (error) return <p></p>;

  return (
    <div className='ubication__container'>
        <div className="ubication__header">
           <h2 className='ubication__title'>Ubicación</h2>
           {/* <a href={data.maps} target="_blank" className='ubication__link' rel="noopener noreferrer">Abrir en Google Maps</a> */}
        </div>
        <div className='ubication__maps'>
            <img src={data.maps} alt="Locations map"/>
        </div>
    </div>
  )
}

export default Location