import React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight, faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import useDataTravel from '../../../../hooks/useDataTravel';

const Gallery = () => {
    const { data } = useDataTravel();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [expandedImage, setExpandedImage] = useState(null);
    const [visibleImagesCount, setVisibleImagesCount] = useState(3); 

    const availableImages = useMemo(() => {
        return data ? data.gallery : [];
    }, [data]);

    const nextSlide = useCallback(() => {
        setCurrentImageIndex(prevIndex => {
            const newIndex = (prevIndex + 1) % availableImages.length;
            setExpandedImage(availableImages[newIndex]);
            return newIndex;
        });
    }, [availableImages]);

    const prevSlide = useCallback(() => {
        setCurrentImageIndex(prevIndex => {
            const newIndex = (prevIndex - 1 + availableImages.length) % availableImages.length;
            setExpandedImage(availableImages[newIndex]);
            return newIndex;
        });
    }, [availableImages]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                nextSlide();
            } else if (event.key === 'ArrowLeft') {
                prevSlide();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [nextSlide, prevSlide]);

    useEffect(() => {
        console.log('Current Image Index:', currentImageIndex);
    }, [currentImageIndex]);

    const handleImageClick = (imageUrl) => {
        const index = availableImages.indexOf(imageUrl);
        if (index !== -1) {
            setCurrentImageIndex(index);
            setExpandedImage(imageUrl);
        }
    };

    const showMoreImages = () => {
        setVisibleImagesCount(prevCount => {
            const remainingImages = availableImages.length - prevCount;
            if (remainingImages > 3) {
                return prevCount + 3;
            } else {
                return prevCount + remainingImages;
            }
        });
    };

    const renderImages = () => {
        return availableImages.slice(0, visibleImagesCount).map((image, index) => (
            <div className={`gallery__row ${index % 3 === 0 ? 'gallery__item-large' : 'gallery__item-small'}`} key={index}>
                <img
                    src={image}
                    alt={`Imagen ${index + 1}`}
                    className={index % 3 === 0 ? 'horizontal' : 'vertical'}
                    onClick={() => handleImageClick(image)}
                />
            </div>
        ));
    };

    if (availableImages.length === 0) {
        return null;
    }

    const remainingImagesCount = availableImages.length - visibleImagesCount;
    const randomImage = availableImages[Math.floor(Math.random() * availableImages.length)];

    return (
        <div className='gallery__container'>
            <h2 className='gallery__title'>Galería de fotos</h2>
            
            <div className='gallery__grid'>
                {renderImages()}
                {remainingImagesCount > 0 && (
                    <button className="img__btn-boton" onClick={showMoreImages}>
                        <div className='overlay'></div>
                        <img src={randomImage} alt='boton para ver mas imagenes'/>
                        <span className='num__increment'>
                            <FontAwesomeIcon icon={faPlus} /> {remainingImagesCount} más
                        </span>            
                    </button>
                )}
            </div>

            {expandedImage && (
                <div className="expanded-image-container">
                    <button className="slider-button-gallery" onClick={prevSlide}>
                        <FontAwesomeIcon className="icon-slide-gallery" icon={faChevronLeft} />
                    </button>
                    <img src={expandedImage} alt="Imagen expandida" className="expanded-image" />
                    <button className="slider-button-gallery" onClick={nextSlide}>
                        <FontAwesomeIcon className="icon-slide-gallery" icon={faChevronRight} />
                    </button>
                    <div className="icon__x" onClick={() => setExpandedImage(null)}>
                        <FontAwesomeIcon icon={faTimes} className='icon__x' />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Gallery;