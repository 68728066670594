const getPromoterFromUrl=()=>{
  const url = window.location.href; // Obtener la URL actual
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;
  const hnamelenght = hostname.split('.').length;
  const subdominio = ( (hnamelenght >=2 && hnamelenght < 4) ? hostname.split('.')[0]: null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pmname = urlParams.get('pmname');

  return subdominio ? subdominio : pmname;
};


export const getPromoterService = async () => {
  
  const promoter = getPromoterFromUrl(); // Obtener el valor del promoter desde la URL

  try {
    const response = require(`../content/promoters/${promoter}.json`);
    return response;
  } catch(error) {
    console.error('Fetch error:', error);
    throw new Error('Promoter no encontrado');
  }
  /*
  let apiUrl;
  const hostname = window.location.hostname;
  
  if (hostname.includes('localhost')) {
    // Use local API endpoint
    apiUrl = `http://localhost:80/promoter?name=${promoter}`;
  } else if (hostname.endsWith('.local')) {
    // Use local API endpoint
    apiUrl = `http://apigw.trippromoter.local/promoter?name=${promoter}`;
  } else {
    // Use production API endpoint
    apiUrl = `https://api.trippromoter.com/promoter?name=${promoter}`;
  }

  try {
    const response = await fetch(apiUrl);

    if (response.status===404){
      throw new Error('Promoter no encontrado');
    }


    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response text:', errorText);
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      const errorText = await response.text();
      console.error('Error: Expected JSON but received:', errorText);
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
  */
};
  