import React, { createContext, useState, useCallback } from 'react';
import { getDataTravel } from '../services/dataTravelService';
import { getPromoterService } from '../services/getPromoterService';

export const DataTravelContext = createContext();

export const DataTravelProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [promoter, setPromoter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async (id) => {
    setLoading(true);
    setError(null);
    try {
      const travelData = await getDataTravel(id);
      const promoterData = await getPromoterService();
      setData(travelData);
      setPromoter(promoterData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <DataTravelContext.Provider value={{ data, promoter, loading, error, fetchData }}>
      {children}
    </DataTravelContext.Provider>
  );
};
