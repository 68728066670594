import React, { useState, useEffect } from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import useDataTravel from '../../../../hooks/useDataTravel';


function TourPlan() {
  const [showFormStates, setShowFormStates] = useState([]);

  const {data}= useDataTravel();

  useEffect(() => {
    if (data && data.plan) {
      setShowFormStates(Array(data.plan.length).fill(false));
    }
  }, [data]);

  const toggleForm = (index) => {
    setShowFormStates(prevState => {
      const newShowFormStates = [...prevState];
      newShowFormStates[index] = !newShowFormStates[index];
      return newShowFormStates;
    });
  };


  return (
    <div className='tourplan__container'>
      <h2>Tour Plan</h2>
      <div className="accordion">
        <ul className='tourplan__ul'>
        {data && data.plan && data.plan.map((item, index) => (
            <li key={index} className='tourplan__li'>
              <div className="accordion-item">
                <button 
                  id={`accordion-button-${index}`} 
                  aria-expanded={showFormStates[index]}
                  onClick={() => toggleForm(index)}
                >
                  <div className='titulares'>
                    <span className="accordion-title">Día {item.day}</span>
                    <span className="accordion-subtitle">{item.title}</span>
                  </div>

                  <FontAwesomeIcon
                    aria-hidden="true"
                    className={`icon ${showFormStates[index] ? 'rotate-180' : ''}`}
                    icon={faAngleDown}
                  />
                  
                </button>
                
                <div className={`accordion-content ${showFormStates[index] ? 'expanded' : ''}`}>
                  
                  <h4 className='content__date'>{item.date}</h4>
                  
                  <p className='content__description'>{item.description}</p>

                {item.activities && item.activities.length > 0 && (
                  <div className="box__hastag">
                    <ul className='hastag__list'>
                      {item.activities.map((activity,actIndex)=>(   
                        <li key={actIndex}>
                          <p>{activity}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                  
                </div>

              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TourPlan;