import React, { useState, useRef, useEffect, forwardRef } from 'react';
import './style.scss';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import whatsapp_icon from './../assets/whatsapp_green.svg';
import useDataTravel from '../../../../../hooks/useDataTravel';


dayjs.extend(isBetween);

const VerticalForm = forwardRef(({ tourId, footerRef }, ref) => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    date: '',
    quantity: '',
    text: ''
  });
  const {data}=useDataTravel();

  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const formRef = useRef(null);
  const buttonRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value || ''
    });
  };

  const onDataChange = (dateString) => {
    setFormData({
      ...formData,
      date: dateString
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\+?[0-9]{1,15}$/;
    return re.test(String(phone));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.fullname || !formData.email || !formData.phone || !formData.date || !formData.quantity || !formData.text) {
      setMessage('Por favor, complete todos los campos.');
      setMessageColor('red');
      return;
    }

    if (!validateEmail(formData.email)) {
      setMessage('Por favor, ingrese un correo electrónico válido.');
      setMessageColor('red');
      return;
    }

    if (formData.phone && !validatePhone(formData.phone)) {
      setMessage('Por favor, ingrese un número de teléfono válido.');
      setMessageColor('red');
      return;
    }

    if (/\d/.test(formData.fullname)) {
      setMessage('El nombre no puede contener números.');
      setMessageColor('red');
      return;
    }

    const startDate = dayjs();
    const endDate = dayjs('2024-12-31');
    const selectedDate = dayjs(formData.date);

    if (!selectedDate.isBetween(startDate, endDate, null, '[]')) {
      setMessage('Por favor, seleccione una fecha dentro del rango permitido (2024).');
      setMessageColor('red');
      return;
    }

    let apiUrl;
    const hostname = window.location.hostname;
    
    if (hostname.endsWith('.local')) {
      // Use local API endpoint
      apiUrl = 'http://api.trippromoter.local/tour/message';
    } else {
      // Use production API endpoint
      apiUrl = 'https://api.trippromoter.com/tour/message';
    }

    try {
      const data = {
        tourId: tourId,
        fullname: formData.fullname,
        email: formData.email,
        phone: formData.phone,
        date: formData.date,
        quantity: parseInt(formData.quantity),
        text: formData.text
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${response.statusText} - ${responseText}`);
      }

      const responseText = await response.text();

      if (response.status === 201) {
        setMessage('Enviado exitosamente');
        setMessageColor('blue');
        setShowForm(false);
        return;
      }

      if (!responseText) {
        setMessage('¡Enviado exitosamente!');
        setMessageColor('blue');
        setShowForm(false);
        return;
      }

      let result;
      try {
        result = JSON.parse(responseText);
        console.log(result);
      } catch (error) {
        throw new Error('Failed to parse JSON response: ' + responseText);
        
      }

      setMessage('Enviado exitosamente');
      setMessageColor('blue');
      setShowForm(false);
    } catch (error) {
      setMessage('No se pudo enviar los datos, intente nuevamente');
      setMessageColor('red');
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    const message = encodeURI('Hola! Queria consultar por el viaje *' + data.title + '*');
    window.open('https://wa.me/5491127516586?text=' + message, '_blank').focus();
  };

  const toggleForm = () => {
    setShowForm(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      if (!buttonRef.current || (buttonRef.current && !buttonRef.current.contains(event.target))) {
        const datePickerElements = document.querySelectorAll('.ant-picker-dropdown');
        let isClickInsideDatePicker = false;

        datePickerElements.forEach(element => {
          if (element.contains(event.target)) {
            isClickInsideDatePicker = true;
          }
        });

        if (!isClickInsideDatePicker) {
          setShowForm(false);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current && footerRef.current && formRef.current) {
        const footerRect = footerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
  
        if (footerRect.top < windowHeight) {
          buttonRef.current.style.position = 'absolute';
          buttonRef.current.style.bottom = `${windowHeight - footerRect.top}px`;
          formRef.current.style.marginBottom = `${windowHeight - footerRect.top}px`;
        } else {
          buttonRef.current.style.position = 'fixed';
          buttonRef.current.style.bottom = '2rem';
          formRef.current.style.marginBottom = '0';
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    handleScroll();
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [footerRef]);
  

  return (
    <div className={`vertical__dropdown`} ref={ref}>
      <button
        ref={buttonRef}
        className={`btn btn-primary`}
        onClick={handleButtonClick}
      >
        <div className={'interior'}>
          <span>Consultar</span>
          <img className='wp_imgg' src={whatsapp_icon} alt='icono de whatsapp'/>
        </div>
      </button>
    </div>
  );
});

export default VerticalForm;