import { useContext } from 'react';
import { ToursContext } from '../context/dataToursContext';

const useTours = () => {
  const context = useContext(ToursContext);
  if (!context) {
    throw new Error('useTours must be used within a ToursProvider');
  }
  return context;
};

export default useTours;