export const getDataTravel = async (id) => {

  try {
    const response = require(`../content/itineraries/${id}.json`);
    return response;
  } catch(error) {
    console.error('Fetch error:', error);
    throw new Error('Itinerario no encontrado');
  }
  
  /*let apiUrl;
  const hostname = window.location.hostname;
  
  if (hostname.includes('localhost')) {
    // Use local API endpoint
    apiUrl = `http://localhost:80/tour/detail?id=${id}`;
  } else if (hostname.endsWith('.local')) {
    // Use local API endpoint
    apiUrl = `http://apigw.trippromoter.local/tour/detail?id=${id}`;
  } else {
    // Use production API endpoint
    apiUrl = `https://api.trippromoter.com/tour/detail?id=${id}`;
  }*

  try {
    const response = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status===404){
      throw new Error('Tour no encontrado');
    }

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response text:', errorText);
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      const errorText = await response.text();
      console.error('Error: Expected JSON but received:', errorText);
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }*/
}