import React from 'react';
import './style.scss';
import useDataTravel from '../../../../hooks/useDataTravel';
import Loader from '../../../../components/Loader/Loader';

const GridGallery = () => {
    const { data, loading, error } = useDataTravel();
    
    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Array con las imágenes disponibles
    const availableImages = data ? data.slider : [];

    // Repetir el patrón 3 veces
    const repeatedImages = [];
    for (let i = 0; i < 3; i++) {
        repeatedImages.push(...availableImages);
    }

    // Crear la lista completa de imágenes duplicadas
    const totalImages = [...repeatedImages, ...repeatedImages]; // Duplicar las imágenes

    return (
        <div className="slider">
         
            
            <div className="slide-track">
                {totalImages.map((image, index) => {
                    const position = index % 3;

                    if (position === 0) {
                        // Columna de imagen vertical
                        return (
                            <div className="slide" key={index}>
                                <img src={image} alt={`Visualizacion`} className="img-vertical" />
                            </div>
                        );
                    } else if (position === 1) {
                        // Columna de dos imágenes horizontales
                        return (
                            <div className="slide" key={index}>
                                <div className="img-horizontal-group">
                                    <img src={image} alt={`Visualizacion`} className="img-horizontal" />
                                    {totalImages[index + 1] && (
                                        <img src={totalImages[index + 1]} alt={`Visualizacion`} className="img-horizontal" />
                                    )}
                                </div>
                            </div>
                        );
                    } else {
                        return null; // Ignorar el índice 2 ya que se maneja en el índice 1
                    }
                })}
            </div>

           
        </div>
    );
};

export default GridGallery;
