import React from 'react';
import instagram from '../Footer/icons/instagram.svg';
import linkedin from '../Footer/icons/linkedin.svg';
import whatsapp from '../Footer/icons/whatsapp.svg';
import twitter from '../Footer/icons/x.svg';
import './style.scss'

const SocialMedia = () => {
  return (
    <div className="social-media-icons">
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <img className="social__media-icon" src={instagram} alt="instagram" />
      </a>
      
      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <img className="social__media-icon" src={linkedin} alt="linkedin" />
      </a>
      <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
        <img className="social__media-icon" src={whatsapp} alt="whatsapp" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <img className="social__media-icon" src={twitter} alt="twitter" />
      </a>
      
      
    </div>
  );
};

export default SocialMedia;