import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './styles.scss';
import GridGallery from './components/GridImage';
import ConsultForm from './components/Form';
import DataTravelComponent from './components/DataTravel';
import Includes from './components/Includes';
import TourPlan from './components/TourPlan';
import Gallery from './components/Gallery';
import VerticalForm from './components/Form/components/FormVertical';
import TitleTour from './components/Title';
import Location from './components/Location';
import useDataTravel from '../../hooks/useDataTravel';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Detail = () => {
  const { id: tourId } = useParams();
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { data, fetchData } = useDataTravel();
  const [loading, setLoading] = useState(true);

  const consultFormRef = useRef(null);
  const footerRef = useRef(null);
  const verticalFormRef = useRef(null);

  useEffect(() => {
    setIsSmallScreen(window.innerWidth <= 800);

    if (tourId) {
      fetchData(tourId).finally(() => {
        setLoading(false);
      });
    } else {
      console.error("No ID provided in URL");
      navigate('/home');
    }

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tourId, fetchData, navigate]);

  const handleBuyClick = () => {
    const message = encodeURI('Hola! Queria consultar por el viaje *' + data.title + '*');
    window.open('https://wa.me/5491127516586?text=' + message, '_blank').focus();
  };

  const hasGalleryImages = data?.gallery?.length > 0;

  const hasMap = data?.maps?.length > 0;

  if (loading) {
    return <Loader />;
  }

  // Array con las imágenes disponibles
  const availableImages = data ? data.slider : [];
  const imgIdx = Math.floor(Math.random() * availableImages.length) + 0

  var currentUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + window.location.search

  return (
    <HelmetProvider>
      <Helmet>
        <title>{data.title}</title>
        <meta property="og:title" content={data.title}/>
        <meta property="og:image" content={availableImages[imgIdx]}></meta>
        <meta data-react-helmet="true" name="description" content={data?.description}/>
        <meta data-react-helmet="true" property="og:description" content={data?.description}/> 
        <meta data-react-helmet="true" property="og:url" content={currentUrl}></meta>
      </Helmet>
      <div className='detail'>
        <Header onBuyClick={handleBuyClick} showGalleryLink={hasGalleryImages} />

        <div className='content-wrapper'>
          <TitleTour />
          <GridGallery />
        </div>

        <div className='main-content'>
          <div className='text-section'>
            <h2 id="datatravel-title">{data?.description}</h2>
            <div id="datatravel">
              <DataTravelComponent />
            </div>
            {isSmallScreen && (
              <VerticalForm ref={verticalFormRef} tourId={tourId} footerRef={footerRef} />
            )}
            <p className='datatravel__p'>{data?.description2}</p>
            <div className='includes' id="includes">
              <Includes />
            </div>
            {isSmallScreen && (
              <VerticalForm ref={verticalFormRef} tourId={tourId} footerRef={footerRef} />
            )}
            <div className='tourplan' id="tourplan">
              <TourPlan />
            </div>
            {hasMap && (
              <div className='map' id="map">
                <Location />
              </div>
            )}
            {hasGalleryImages && (
              <div className='gallery' id="gallery">
                <Gallery />
              </div>
            )}
          </div>

          <div className='aside'>
            {!isSmallScreen && (
              <div className='form-container'>
                <ConsultForm ref={consultFormRef} tourId={tourId} />
              </div>
            )}
          </div>
        </div>

        <Footer ref={footerRef} />
      </div>
    </HelmetProvider>
  );
};

export default Detail;